import instagram from "../img/instagram.svg";
import linkedin from "../img/linkedin.svg";
import contact from "../img/contact.png";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class SocialLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  render() {
    return (
      <div className='socials'>
        <NavLink to="/playtime" className='pt'><img src={contact} alt="Contact Us"  /></NavLink>
        <a href='https://www.instagram.com/ajansdigitaltr/' rel="noreferrer" target='_blank'><img src={instagram} alt="Instagram" /></a>
        <a href='https://www.linkedin.com/company/ajansdigital/' rel="noreferrer" target='_blank'><img src={linkedin} alt="Linkedin" /></a>
      </div>
    );
  }
}
