import React, { Component } from "react";
import orangedot from "../img/orangedot.png"

export default class Creativity extends Component {
  componentDidMount() {
    document.title = "Ajans Digital - Creativity & Strategy";
  }

  render() {
    return (
      <div className="subContent creativity has-text-centered is-align-items-center is-flex is-flex-direction-column">
        <div className="creativityContent cnt">
          <h2>CREATIVITY & STRATEGY</h2>
          <p>
            At Ajans Digital, it is all about the perfect balance.
            <br />
            Head over hills creative ideas are combined with solid strategies.
          </p>
        </div>
        <p className="orange is-hidden-mobile">
          Branding and Positioning <img src={orangedot} alt='Orange Dot'  /> Strategy and Planning <img src={orangedot} alt='Orange Dot' /> <br />Concept Development
          <img src={orangedot} alt='Orange Dot' /> Creative Campaigns <img src={orangedot} alt='Orange Dot' /> Launch Campaigns <img src={orangedot} alt='Orange Dot' /> <br />Social Awareness Campaigns <img src={orangedot} alt='Orange Dot' />  Internal Communication <img src={orangedot} alt='Orange Dot' /> <br />Congress/Meeting Activities
        </p>
        <div className="orange is-hidden-tablet">
          <div><img src={orangedot} alt='Orange Dot' />Branding and Positioning </div>
          <div><img src={orangedot} alt='Orange Dot' />Strategy and Planning</div>
          <div><img src={orangedot} alt='Orange Dot' />Concept Development</div>
          <div><img src={orangedot} alt='Orange Dot' />Creative Campaigns</div>
          <div><img src={orangedot} alt='Orange Dot' />Launch Campaigns</div>
          <div><img src={orangedot} alt='Orange Dot' />Social Awareness Campaigns</div>
          <div><img src={orangedot} alt='Orange Dot' />Internal Communication</div>
          <div><img src={orangedot} alt='Orange Dot' />Congress/Meeting Activities</div>
        </div>
      </div>
    );
  }
}
