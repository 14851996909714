const prizes = [
  {
    year: 2024,
    id :'prize2024',
    prizes: [
      {
        name: "MUSE CREATIVE AWARDS",
        list: [
          {
            project: "«Değişen Nefesler Dönüşen Hayatlar» Yapay Zeka Projesi",
            brand: "Sandoz",
            category: "Branded Content - Healthcare & Pharma",
            grade: "gold",
          },
          {
            project: "Lenfomart Lenfoma Farkındalık Galerisi",
            brand: "Takeda",
            category: "Website - Health",
            grade: "gold",
          },
          {
            project: "«Değişen Nefesler Dönüşen Hayatlar» Sesli Kutu Projesi",
            brand: "Sandoz",
            category: "Branded Content - Healthcare & Pharma",
            grade: "silver",
          },
          {
            project: "Lenfomart Lenfoma Farkındalık Galerisi",
            brand: "Takeda",
            category: "Branded Content - Healthcare & Pharma",
            grade: "silver",
          }
        ],
      },
      {
        name: "BRANDVERSE AWARDS",
        list: [
          {
            project: "Lenfomart Lenfoma Farkındalık Galerisi",
            brand: "Takeda",
            category: "Medya - Sağlık",
            grade: "silver",
          },
          {
            project: "\"DNDH\" Yapay Zeka Projesi",
            brand: "Sandoz",
            category: "Medya - Yapay Zeka Çalışmaları",
            grade: "shortlist",
          }
        ],
      },
      {
        name: "MARTECH TEKNOLOJİ ÖDÜLLERİ",
        list: [
          {
            project: "\"DNDH\" Sesli Kutu Projesi",
            brand: "Sandoz",
            category: "Sağlık Sektöründe En İyi Teknoloji Kullanımı",
            grade: "shortlist",
          }
        ],
      }
    ],
  },
  {
    year: 2023,
    id :'prize2023',
    prizes: [
      {
        name: "FELIS AWARDS",
        list: [
          {
            project: "«Değişen Nefesler Dönüşen Hayatlar» Yapay Zeka Projesi",
            brand: "Sandoz",
            category: "Healthcare & Wellness – Hedef Kitle Odaklı Medya Kullanımı ve Topluluk Yaratma",
            grade: "felis",
          },
          {
            project: "«Değişen Nefesler Dönüşen Hayatlar» Yapay Zeka Projesi",
            brand: "Sandoz",
            category: "Healthcare & Wellness – Medikal Pazarlama Kampanyası",
            grade: "felis",
          },
          {
            project: "«Değişen Nefesler Dönüşen Hayatlar» Sesli Kutu Projesi",
            brand: "Sandoz",
            category: "Healthcare & Wellness – Hedef Kitle Odaklı Medya Kullanımı ve Topluluk Yaratma",
            grade: "merit",
          },
          {
            project: "Lenfomart Lenfoma Farkındalık Galerisi",
            brand: "Takeda",
            category: "Healthcare & Wellness – Medikal Pazarlama Kampanyası",
            grade: "merit",
          },
          {
            project: "Lenfomart Lenfoma Farkındalık Galerisi",
            brand: "Takeda",
            category: "Healthcare & Wellness – Ürün İnovasyon İletişimi",
            grade: "merit",
          },
        ],
      },
      {
        name: "ISTANBUL MARKETING AWARDS",
        list: [
          {
            project: "«Değişen Nefesler Dönüşen Hayatlar» Yapay Zeka Projesi",
            brand: "Sandoz",
            category: "Sağlık Profesyonellerine Yönelik Sağlık İletişimi",
            grade: "gold",
          },
          {
            project: "«Değişen Nefesler Dönüşen Hayatlar» Yapay Zeka Projesi",
            brand: "Sandoz",
            category: "Veriyi Teknolojiyle Birleştirme",
            grade: "gold",
          },
          {
            project: "Lenfomart Lenfoma Farkındalık Galerisi",
            brand: "Takeda",
            category: "Yeni Websitesi",
            grade: "gold",
          },
        ],
      },
      {
        name: "ALTIN HAVAN",
        list: [
          {
            project: "«Değişen Nefesler Dönüşen Hayatlar» Sesli Kutu Projesi",
            brand: "Sandoz",
            category: "İnovatif Ürün Tanıtımı",
            grade: "gold",
          }
        ],
      },
    ],
  },
  {
    year: 2022,
    id :'prize2022',
    prizes: [
      {
        name: "DOĞRUDAN PAZARLAMA ÖDÜLLERİ",
        list: [
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Etkinlik Kategorisi - Lansman Aktiviteleri",
            grade: "gold",
          },
          {
            project: "Umudun Hikayesi",
            brand: "Santa Farma",
            category: "Dijital Kategorisi - Craft: Kullanıcı Deneyimi ve Arayüz",
            grade: "bronze",
          },
        ],
      },
      {
        name: "MUSE CREATIVE AWARDS",
        list: [
          {
            project: "Umudun Hikayesi",
            brand: "Santa Farma",
            category: "Website-Health",
            grade: "gold",
          },
        ],
      },
    ],
  },
  {
    year: 2021,
    id :'prize2021',
    prizes: [
      {
        name: "MUSE CREATIVE AWARDS",
        list: [
          {
            project: "KTEPH AKLIMDA",
            brand: "Bayer Türk",
            category: "Audio - Other",
            grade: "gold",
          },
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Video - Medical",
            grade: "silver",
          },
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Strategic Program - Product Launch",
            grade: "silver",
          }
        ],
      },
      {
        name: "FELIS AWARDS",
        list: [
          {
            project: "Şeker Hastalar",
            brand: "Astra Zeneca",
            category: "Sağlık İletişimi<br />- Detailing Çalışması",
            grade: "felis",
          },
          {
            project: "Umudun Hikayesi",
            brand: "Santa Farma",
            category: "Sağlık İletişimi<br />- Detailing Çalışması",
            grade: "merit",
          },
          {
            project: "Yeni Çağ Şövalyeleri Turnuvası",
            brand: "GSK",
            category: "Sağlık İletişimi<br />- İç İletişimi Çalışması",
            grade: "merit",
          }
        ],
      },
      {
        name: "ISTANBUL MARKETING AWARDS",
        list: [
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Sanat ve Marka İşbirliği",
            grade: "gold",
          },
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Film ve Dijital Video Platformu",
            grade: "gold",
          }
        ],
      },
    ],
  },
  {
    year: 2020,
    id : 'prize2020',
    prizes: [
      {
        name: "SUMMIT CREATIVE AWARDS",
        list: [
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Health / Medicine Video ",
            grade: "gold",
          },
        ],
      },
      {
        name: "STEVIE INTERNATIONAL BUSINESS AWARDS",
        list: [
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Brand & Experiences - Launch Event",
            grade: "gold",
          },
          {
            project: "Nucala Lansman",
            brand: "GSK",
            category: "Marketing Campaign of the Year - Healthcare - Rx",
            grade: "gold",
          },
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Marketing Campaign of the Year - Healthcare - Disease Education & Awareness",
            grade: "bronze",
          },
          {
            project: "Chain Rising Star",
            brand: "Takeda",
            category: "Marketing Campaign of the Year - Healthcare - Disease Education & Awareness",
            grade: "bronze",
          },
        ],
      },
      {
        name: "FELIS AWARDS",
        list: [
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Sağlık Profesyonelleri İletişimi - Lansman/Re-Lansman Kampanyası",
            grade: "felis",
          },
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Sağlık Profesyonelleri İletişimi - Medikal Pazarlama Kampanyası",
            grade: "merit",
          },
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Sağlık Profesyonelleri İletişimi - Ürün, Etki Mekanizması Videosu",
            grade: "merit",
          },
        ],
      },
      {
        name: "KRİSTAL ELMA",
        list: [
          {
            project: "Yolculuk",
            brand: "Takeda",
            category: "Medya Kullanımı - Sinema Uygulaması",
            grade: "kristal",
          },
        ],
      },
    ],
  },
  {
    year: 2019,
    id :'prize2019',
    prizes: [
      {
        name: "FELIS AWARDS",
        list: [
          {
            project: "KTEPH AKLIMDA",
            brand: "Bayer Türk",
            category: "Sağlık İletişimi - Eğitim/ Bilgilendirme Projesi",
            grade: "felis",
          },
          {
            project: "KTEPH AKLIMDA",
            brand: "Bayer Türk",
            category: "Sağlık İletişimi - Sempozyum ve Toplantı Çalışması",
            grade: "merit",
          },
          {
            project: "Chain Rising Star",
            brand: "Takeda",
            category: "Sağlık İletişimi - Medikal Pazarlama Kampanyası",
            grade: "merit",
          },
          {
            project: "Chain Rising Star",
            brand: "Takeda",
            category: "Sağlık İletişimi - Eğitim/ Bilgilendirme Projesi",
            grade: "merit",
          },
        ],
      },
    ],
  },
  {
    year: 2018,
    id : 'prize2018',
    prizes: [
      {
        name: "STEVIE INTERNATIONAL BUSINESS AWARDS",
        list: [
          {
            project: "3D Dinamik Dinleme Deneyimi",
            brand: "Santa Farma",
            category: "Best New Product or Service of the Year - Content - Multichannel Publishing Technology",
            grade: "gold",
          },
          {
            project: "TND Mobil Aplikasyon",
            brand: "Neonatoloji Derneği & Chiesi",
            category: "Apps & Mobile Websites - Health",
            grade: "gold",
          },
          {
            project: "Flu VR Game",
            brand: "GSK Grip Farkındalık",
            category: "Apps & Mobile Websites - Games",
            grade: "silver",
          },
          {
            project: "Derideki Hisler Derindeki İzler",
            brand: "GSK Dermatoloji",
            category: "Marketing Campaign of the Year - Healthcare - Prescription (Rx)",
            grade: "bronze",
          },
        ],
      },
      {
        name: "FELIS AWARDS",
        list: [
          {
            project: "Derideki Hisler Derindeki İzler",
            brand: "GSK Dermatoloji",
            category: "Sağlık İletişimi - Eğitim/ Bilgilendirme Projesi",
            grade: "merit",
          },
          {
            project: "TND Mobil Aplikasyon",
            brand: "Neonatoloji Derneği & Chiesi",
            category: "Sağlık İletişimi - Websitesi/Aplikasyon",
            grade: "merit",
          },
        ],
      },
      {
        name: "CLIO HEALTH AWARDS",
        list: [
          {
            project: "3D Dinamik Dinleme Deneyimi",
            brand: "Santa Farma",
            category: "Audio Technique  / Disease Awareness",
            grade: "shortlist",
          },
        ],
      },
    ],
  }
];

export default prizes;
