import React, { Component } from "react";

export default class Playtime extends Component {
  componentDidMount() {
    document.title = "Ajans Digital - Play Time";
  }

  render() {
    return (
      <div className="subContent playtime is-flex is-align-items-center is-justify-content-center has-text-centered is-flex-direction-column">
        <div className="playtimeOverlay">
        <h2>PLAY TIME</h2>
          <div style={{ fontSize: "26px" }}>
            PLEASE GET IN TOUCH
            <br />
            AND
          </div>
          <div className='letthe'>LET THE GAME BEGIN</div>
          <div className='mail'>
            <a href="mailto:info@ajansdigital.com">info@ajansdigital.com</a><br />
            <a href='tel:+902129092216'>0(212) 909 22 16</a>
          </div>
        </div>
      </div>
    );
  }
}
