import React, { Component } from "react";

export default class Creativity extends Component {
  componentDidMount() {
    document.title = "Ajans Digital - Medical Communication";
  }

  render() {
    return (
      <div className="subContent medical back is-flex is-align-items-center is-justify-content-center is-flex-direction-column has-text-centered">
        <div className="medicalContent is-flex-grow-1 cnt">
          <h2>MEDICAL COMMUNICATION</h2>
          <p>
            In-house medical advisors giving support to all medico-marketing
            activities of our clients.
          </p>
        </div>
        <div className="medicalFooter">
          <div className="is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
            <span className="is-uppercase">Medical strategy building</span>
            New product launches + life cycle management of commercially
            available products
          </div>
          <div className="is-flex is-align-items-center is-justify-content-center ">
            <span className="is-uppercase">Medical training</span>
          </div>
          <div className="is-flex is-align-items-center is-justify-content-center">
            <span className="is-uppercase">
              Medical writing / referencing / translation
            </span>
          </div>
          <div className="is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
            <span className="is-uppercase">Medicomarketing activities</span>
            included but not limited to e-detail aids, slide sets, monographs
          </div>
          <div className="is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
            <span className="is-uppercase">KOL communications</span>
            included but not limited to advisory board planning/ execution/
            final report creation
          </div>
        </div>
      </div>
    );
  }
}
