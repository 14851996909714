import React, { Component } from "react";
import x from "../img/x.png";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.closePopupFunc = this.closePopupFunc.bind(this);
    this.myVideo = React.createRef();
    this.state = { popup : true}
  }



  componentDidMount() {
    document.title = "Ajans Digital";
    let vid = this.myVideo.current.querySelectorAll("video")[0];
    let pop = this.myVideo.current.querySelectorAll("button")[0];
    vid.currentTime = this.props.onGet();
    let bu = this.props.onDur;
    vid.ontimeupdate = function () {
      bu(vid.currentTime);
    };

    let closepop = document.getElementById("closepop");
    if (closepop) {
      closepop.addEventListener("click", this.closePopupFunc);
    }

  }

  closePopupFunc() {
    sessionStorage.setItem("popup_sess", false);
  }

  render() {
    var vidSrc = `${process.env.PUBLIC_URL + "/ajans_showreel_03.mp4"} `;
    var popup_sess = sessionStorage.getItem("popup_sess");
    return (
      <div className="subContent home is-flex is-align-items-center is-justify-content-center is-flex-direction-column">

        {!popup_sess &&
          <div className='popup'>
            <button id="closepop" className="close" onClick={() => this.setState({ popup: !this.state.popup })}>
              <img src={x} alt="Close popup"/>
            </button>

            <h1>PROUD TO ANNOUNCE</h1>
            <p> The best agencies of Turkey are listed by MediaCat<br />based on their creativity and awards received.
            <br /><b>And Ajans Digital ranked high on the list as 22th!</b>
            </p>
            <br />
            <img src={`${process.env.PUBLIC_URL + "/img/top22.png"}`} />
          </div>
        }
        <div className="homeOverlay is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
          <div className="subTitle">
            BRANDCARE AGENCY
          </div>
        </div>

        <div
          ref={this.myVideo}
          dangerouslySetInnerHTML={{
            __html: '<video loop autoplay playsinline muted><source type="video/mp4" src="' + vidSrc + '"/></video>',
          }}
        ></div>
      </div>
    );
  }
}
