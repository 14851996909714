import React, { Component } from "react";

export default class Playfellows extends Component {
  constructor(props) {
    super(props);
    this.fellows = [
      ["gsk", "roche", "eczacibasi", "teva", "tnd" ],
      ["bayer", "msd", "chiesi", "takeda", "HeyBringo"],
      ["abdi_ibrahim", "santa_farma", "boehringer", "astrazeneca","alpinia_institute"],
      ["sandoz_2","novonordisk", "recordati_2", "biomarin_2", "maxicells_2"],
      ["acibadem","amgen", "abbott", "venatura_2", "ttod_2"],
    ];
  }

  componentDidMount() {
    document.title = "Ajans Digital - Play Fellows";
  }

  render() {
    return (
      <div className="playfellows">
        <div className="playOverlay cnt is-flex is-align-items-center is-justify-content-center">
          <h2>PLAYFELLOWS</h2>
        </div>
        <div className="playList">
          <div className="columns">
              {this.fellows.map( (name,j) => {
                let logos = name.map((k,i) => {
                    return <img key={i} alt={k} src={`${process.env.PUBLIC_URL + "/img/logos/" + k + '.png'} `} />;
                  } )
                return <div key={j} className='column pb-0 pt-0 is-flex is-flex-direction-column is-align-items-center is-justify-content-space-around'>{logos}</div>;
              })}
          </div>

        </div>
      </div>
    );
  }
}
