import "./scss/App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Arrow from "./components/Arrow";
import React, { Component, useState} from "react";
import SlideRoutes from "react-slide-routes";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";

import Home from "./pages/Home";
import About from "./pages/About";
import Creativity from "./pages/Creativity";
import Medical from "./pages/Medical";
import Digital from "./pages/Digital";
import Playfellows from "./pages/Playfellows";
import Playtime from "./pages/Playtime";
import Fairplay from "./pages/Fairplay";
import SocialLinks from "./components/Sociallinks";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { nextItem: "", videoTime: 0, };
    this.routes = [
      "/",
      "/world-is-our",
      "/creativity-and-strategy",
      "/medical-communication",
      "/digital-solutions",
      "/playfellows",
      "/fairplay",
      "/playtime",
    ];
    this.updateTime = this.updateTime.bind(this);
    this.getTime = this.getTime.bind(this);
  }

  updateTime(dur) {
    this.setState({ videoTime: dur });
  }

  getTime(){
    return this.state.videoTime;
  }

  render() {
    let homeActive = this.props.location.pathname === "/" ? "home" : "";

    return (

      <div className={"App " + homeActive}>
        <div className="container">
          <Header />
          <SocialLinks />
          <main className="theContent">
            <div className="contentBorder"></div>
            <SlideRoutes location={this.props.location} duration={500} pathList={this.routes}>
              <Route path="/" render={(props) => <Home {...props} onDur={this.updateTime} onGet={this.getTime} />} exact />
                <Route path="/world-is-our" component={About} />
              <Route path="/creativity-and-strategy" component={Creativity} />
              <Route path="/medical-communication" component={Medical} />
              <Route path="/digital-solutions" component={Digital} />
              <Route path="/playfellows" component={Playfellows} />
              <Route path="/fairplay" component={Fairplay} />
              <Route path="/playtime" component={Playtime} />
            </SlideRoutes>
          </main>
          <Arrow direction="left" routes={this.routes} />
          <Arrow direction="right" routes={this.routes} />
          <Footer />
        </div>
      </div>

    );
  }
}


export default withRouter(App);
