import arrow from "../img/arrow.svg";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";

class Arrow extends Component {
  render() {
    let curIndex = this.props.routes.indexOf(this.props.location.pathname);
    let show = "";
    let link = "";
    if (this.props.direction === "left") {
      if (curIndex > 0) {
        link = this.props.routes[curIndex - 1];
      } else {
        show = "hide";
      }
    }
    if (this.props.direction === "right") {
      if (curIndex < this.props.routes.length - 1) {
        link = this.props.routes[curIndex + 1];
      } else {
        show = "hide";
      }
    }

    return (
      <NavLink to={link} className={"arrow " + this.props.direction + " " + show}>
        <img src={arrow} alt="arrow" width="25" />
      </NavLink>
    );
  }
}

export default withRouter(Arrow);
