import React, { Component } from "react";
import misket from "../img/misket.svg";
import topac from "../img/topac.svg";
import ucurtma from "../img/ucurtma.svg";
import yoyo from "../img/yoyo.svg";

export default class About extends Component {
  componentDidMount() {
    document.title = "Ajans Digital - World is Our Playground";
  }

  render() {
    return (
      <div className="subContent about back is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
        <div className="aboutContent is-flex-grow-1 cnt">
          <h2>WORLD IS OUR PLAYGROUND</h2>
          <p className="mb-5">
            An award-winning full-service creative advertising agency, founded in 2003, providing healthcare communication and strategy
            services with its 25 passionate and dedicated talents from all around the world.{" "}
          </p>
          <p>Advertising is a game, which we take very seriously.</p>
          <p className="textblue has-text-weight-medium">
            We are not just another agency you can take services, but we are your playmates offering you the world as your playground.
          </p>
        </div>
        <div className="aboutFooter">
          <div className="columns is-gapless is-multiline is-mobile">
            <div className="column is-flex is-align-items-center is-justify-content-center is-3-desktop is-6-tablet is-6-mobile">
              <img src={topac} alt="Topaç" width="55" />
            </div>
            <div className="column is-flex is-align-items-center is-justify-content-center is-3-desktop is-6-tablet is-6-mobile">
              <img src={ucurtma} alt="Uçurtma" width="50" />
            </div>
            <div className="column is-flex is-align-items-center is-justify-content-center is-3-desktop is-6-tablet is-6-mobile">
              <img src={yoyo} alt="Yoyo" width="45" />
            </div>
            <div className="column is-flex is-align-items-center is-justify-content-center is-3-desktop is-6-tablet is-6-mobile">
              <img src={misket} alt="Misket" width="50" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
