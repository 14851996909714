import React, { Component, createRef } from "react";

export default class Game extends Component {
  constructor() {
    super();
    this.state = {
      enemies: [],
      bullets: [],
      bulletPos: 0,
    };
    this.ref = createRef();
    this.ship = createRef();
    this.offset = 80;
    this.enemyWidth = 50;
    this.shipWidth = 62;
    this.diff = 0;
    this.bulletCount = 0;
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    let width = this.ref.current.offsetWidth;
    let enemies = [];
    for (let a = 0; a < 3; a++) {
      for (let i = 0; i < Math.round((width - 100) / this.offset); i++) {
        let left = i * this.offset + this.enemyWidth;
        let top = a * this.enemyWidth + this.enemyWidth;
        let ind = a < 2 ? 1 : a;
        enemies.push(
          React.createElement("div", {
            key: "" + a + i,
            style: { position: "absolute", left: left + "px", top: top + "px" },
            className: "enemy enemy" + ind,
          })
        );
      }
    }
    this.setState({ enemies: enemies });
    this.update();
  }

  fire() {
    var joined = this.state.bullets.concat(
      React.createElement("div", {
        key: "bullet" + this.bulletCount,
        style: {
          position: "absolute",
          left: this.diff,
          bottom: 50,
        },
        className: "bullet",
      })
    );
    this.bulletCount++;
    this.setState({ bullets: joined });
  }

  update() {
    if (this.state.bullets.length) {
      var x = document.getElementsByClassName("bullet");
      var i;
      for (i = 0; i < x.length; i++) {
        let vo = x[i].getBoundingClientRect();
        x[i].style.bottom = `${parseInt(x[i].style.bottom) + 10}px`;
        if (this.collisionDetection(vo.x, vo.y)) {
          x[i].remove();
        }
      }
    }
    requestAnimationFrame(this.update);
  }

  handleMouseMove(e) {
    this.diff = e.nativeEvent.clientX - this.ref.current.getBoundingClientRect().x;
    this.ship.current.style.transform = "translate(" + (this.diff - this.shipWidth / 2) + "px)";
  }

  finishGame(){
    let ship = this.ship.current;
    let finished = setInterval( function(){
      ship.classList.toggle("shipwin");
    },500);
    setTimeout(function(){clearInterval(finished); window.location.reload(); },5000);
  }

  collisionDetection(x, y) {
    let el = document.elementFromPoint(x, y + 20);
    if (el && el.classList.contains("enemy")) {
      el.remove();
      if(document.querySelectorAll(".enemy").length === 0){
        this.finishGame();
      }
      return true;
    } else if (el && el.classList.contains("contentBorder")) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div
        className="spaceInvaders"
        ref={this.ref}
        onClick={this.fire.bind(this)}
        onMouseMove={this.handleMouseMove.bind(this)}
      >
        {this.state.enemies}
        {this.state.bullets}
        <div className="ship" ref={this.ship}></div>
      </div>
    );
  }
}
