import React, { Component, useContext, useState } from "react";
import prizes from "../data/prizes";

import x from "../img/x.png";

import Header , {} from "../components/Header";


export default class Fairplay extends Component {
  constructor(props) {
    super(props);
    this.state = { prizeShow: false, activePrize: null, year:null };
    this.prizes = prizes;
  }

  handlePopupClick(item) {
    this.setState({ prizeShow: true, activePrize: item, year: item.year});
  }

  changePopupYear(item) {
    this.setState({ prizeShow: true, activePrize: item, year: item.year });

    [].forEach.call(document.querySelectorAll('.popup_year'), function (el) {
      el.classList.remove('active');
    });

    const currentYear = document.querySelector('[data-year="'+item.year+'"]');
    currentYear.classList.add('active');


  }

  componentDidMount() {
    document.title = "Ajans Digital - Fair Play Fair Win";
  }


  render() {
    return (
      <div className="subContent fairPlay">
        <div id="fairMain" className={this.state.prizeShow ? "active" : ""}>
          <div className="fairPlayOverlay cnt is-flex is-align-items-center is-justify-content-center">
            <h2 className='has-text-centered'>FAIR PLAY<br />FAIR WIN</h2>
          </div>
          <div className="fairplayList">
            <div className="columns flex-wrap">
              {this.prizes.map((item, j) => {
                return (
                  <div
                    key={j}
                    onClick={() => this.handlePopupClick(item)}
                    className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center "
                  >
                    <div className="year">{item.year}</div>
                    <img alt={item.year} id={item.id} key={j} src={`${process.env.PUBLIC_URL + "/img/prizes/" + item.year + ".png"} `} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {this.state.prizeShow && (
          <div className="popup cnt has-text-centered">
            <button className="close" onClick={() => this.setState({ prizeShow: false })}>
              <img src={x} alt="Close popup" />
            </button>
            <div className="fairplay-popup-year">
              {this.prizes.map((item, j) => {
                return (
                  <p
                  key={j}
                  onClick={() => this.changePopupYear(item)}
                  className={`${this.state.year ==  item.year ? "active" : ""} popup_year`}
                  data-year={item.year}>{item.year}</p>
                );
              })}
            </div>

            <div className="columns is-6">
              {this.state.activePrize.prizes.map((item, i) => {
                return (
                  <div key={i} className="column">
                    <table className="protable">
                      <tbody>
                        <tr className="projects">
                          <td></td>
                          <td className="ptitle">{item.name}</td>
                        </tr>
                        {item.list.map((subItem, j) => {
                          return (
                            <tr key={j} className="projects">
                              <td className={"grade " + subItem.grade}>{subItem.grade}</td>
                              <td className="desc">
                                <div className="category" dangerouslySetInnerHTML={{__html: subItem.category}}></div>
                                <div className="project">{subItem.project}</div>
                                <div className="brand">{subItem.brand}</div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
