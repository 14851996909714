import logo from "../img/logo.svg";
import instagram from "../img/instagram.svg";
import linkedin from "../img/linkedin.svg";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "../scss/Header.scss";


export default class Header extends Component {
  constructor(props) {
    super(props);
    this.closeMenuFunction = this.closeMenuFunction.bind(this);
    this.state = {
      isActive: false,
      value: 0
    };

    this.myRef = React.createRef();
  }


  componentDidMount() {
    const links = this.myRef.current.querySelectorAll("a");
    if (window.screen.width < 1024) {
      window.onscroll = function (e) {
        const theHeader = document.getElementById("theHeader");
        if (window.scrollY > 100) {
          theHeader.classList.add("sticky");
        }else {
          theHeader.classList.remove("sticky");
        }
      };
    }

    for (let i = 0; i < links.length; i++) {
      // links[i].addEventListener("click", this.scrollBottom);
      if (window.screen.width < 1024) {
        links[i].addEventListener("click", this.closeMenuFunction);
      }
    }

    const mainLogo = document.getElementById("headerlogo");
    mainLogo.addEventListener("click", this.closeMenuFunction);

    const mainLogoInner = document.getElementById("headerlogo-inner");
    mainLogoInner.addEventListener("click", this.closeMenuFunction);

  }

  scrollBottom(){
    setTimeout( function(){
      document.getElementsByClassName("theContent")[0].scrollIntoView({behavior: "smooth"});
    },500);
  }


  closeMenuFunction() {
    const burger = document.getElementById("headerMenuBurger");
    burger.classList.remove("is-active");
    const menu = document.getElementById("navbarBasicExample");
    menu.classList.remove("is-active");
    this.setState({ isActive: false });
  }

  render() {
    return (
      <header>
        <nav id="theHeader" className="theHeader navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand is-align-items-center">
            <NavLink id="headerlogo" to="/" exact>
              <img
                src={logo}
                alt="AjansDigital Logo"
                width="180"
                className="navbar-item logo"
              />
            </NavLink>

            <a
              id="headerMenuBurger"
              role="button"
              className={`${this.state.isActive ? "is-active" : ""} navbar-burger burger`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={() => this.setState({ isActive: !this.state.isActive })}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" className={`${this.state.isActive ? "is-active" : ""} navbar-menu is-shadowless`}>
            <div className="navbar-brand is-align-items-center is-hidden-desktop">
              <NavLink id="headerlogo-inner" to="/" exact>
                <img
                  src={logo}
                  alt="AjansDigital Logo"
                  width="120"
                  height="50"
                  className="navbar-item"
                />
              </NavLink>

              <a
                id="headerMenuBurger"
                role="button"
                className={`${this.state.isActive ? "is-active" : ""} navbar-burger burger`}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                onClick={() => this.setState({ isActive: !this.state.isActive })}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
            <div className="navbar-end">
              <ul ref={this.myRef}>
                <li ><NavLink to="/world-is-our">world is our <br />playground</NavLink></li>
                <li ><NavLink to="/creativity-and-strategy">creativity & <br />strategy</NavLink></li>
                <li ><NavLink to="/medical-communication">medical <br />communication</NavLink></li>
                <li ><NavLink to="/digital-solutions">digital <br />solutions</NavLink></li>
                <li ><NavLink to="/playfellows">playfellows</NavLink></li>
                <li ><NavLink to="/fairplay">fair play <br />fair win</NavLink></li>

                <li className='is-hidden-desktop'><NavLink to="/playtime">contact us</NavLink></li>
                <li className='is-hidden-desktop'>
                  <a href='https://www.instagram.com/ajansdigitaltr/' rel="noreferrer" target='_blank'><img width="40" src={instagram} alt="Instagram" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href='https://www.linkedin.com/company/ajansdigital/' rel="noreferrer" target='_blank'><img width="40" src={linkedin} alt="Linkedin" /></a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
