import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
            <strong>AjansDigital</strong> 2020 by <a href="http://www.emredemirhan.com/en">Emre Demirhan</a>
        </div>
      </footer>
    );
  }
}
