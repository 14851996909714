import React, { Component } from "react";
import Game from "../components/Game";

export default class Digital extends Component {
  componentDidMount() {
    document.title = "Ajans Digital - Digital Solutions";
  }

  render() {
    return (
      <div className="subContent digital is-flex is-align-items-center is-justify-content-center is-flex-direction-column has-text-centered">
        <div className="digitalContent cnt is-flex-grow-1">
          <h2>DIGITAL SOLUTIONS</h2>
          <p>
            In-house digital communication and production team, <br />
            which allows us to serve our clients in a fast and efficient way.
          </p>
        </div>
        <div className='digitalFooter'>
          Video Production<br />
          Social Media<br />
          Interactive Technologies<br />
          Social Listening and Analysis<br />
          Desktop & Mobile App Development<br />
          Web Design & Website Development<br />
          VR & AR Game Development
        </div>
        <Game />
      </div>
    );
  }
}
